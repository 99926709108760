/** @jsxImportSource theme-ui */

import Avatar from "@swvl/avatar";
import StarRating from "./StarRating";
import AvatarDefault from "assets/avatar-default.png";
import ReviewIcon from "shared/icons/ReviewIcon";
import RideIcon from "shared/icons/RideIcon";
import {
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { groupBy } from "utils";
import DotIcon from "shared/icons/DotIcon";
import { CaptainRating } from "resources/common/useCaptainRating";

const TODAY = new Date();
const getTimeKey = (createdAt?: string): [string, number] => {
  if (!createdAt) return ["", 0];

  const days = differenceInDays(TODAY, new Date(createdAt));
  const months = days > 30 ? differenceInMonths(TODAY, new Date(createdAt)) : 0;
  const years = days > 365 ? differenceInYears(TODAY, new Date(createdAt)) : 0;

  if (years === 1) return ["one_year_ago", years];
  if (years > 1) return ["years_ago", years];
  if (months === 1) return ["one_month_ago", months];
  if (months > 1) return ["months_ago", months];
  if (days > 1) return ["days_ago", days];
  return ["today", days];
};

const ReviewItem = ({ review }: { review: CaptainRating }) => {
  const { t } = useTranslation("captains");
  const [timeKey, timeValue] = useMemo(
    () => getTimeKey(review.user.createdAt),
    [review.user.createdAt]
  );
  const feedbackByCategory = groupBy(review.feedback, "category");
  const categories = Object.keys(feedbackByCategory).filter(
    (val) => val !== "undefined"
  );

  return (
    <div
      sx={{
        padding: "32px 0",
        borderBottom: "1px solid rgba(217, 219, 233, 1)",
      }}
    >
      <div
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
        }}
      >
        <div sx={{ display: "flex", alignItems: "start", gap: 12 }}>
          <Avatar src={AvatarDefault} size={40} />
          <div>
            <p sx={{ fontSize: 14, fontWeight: 500, margin: "0 0 10px" }}>
              {review?.user?.name}
            </p>
            <div sx={{ display: "flex", alignItems: "end", gap: 3 }}>
              <StarRating rating={review?.user?.rating} />
              <span
                sx={{
                  color: "content-tertiary",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                {(t(timeKey) || "").replace("{value}", timeValue.toString())}
              </span>
            </div>
            <div>
              {categories.map((category) => {
                const feedback = feedbackByCategory[category];
                return (
                  <div
                    key={category}
                    sx={{
                      display: "flex",
                      mt: "spacing-m",
                    }}
                  >
                    <div
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "spacing-xs",
                        alignSelf: "flex-start",
                        paddingInlineEnd: "spacing-xs",
                        width: 150,
                      }}
                    >
                      <ReviewIcon sx={{ minWidth: "16px" }} />
                      <span
                        sx={{
                          variant: "text.p-x-small-medium",
                          fontWeight: 600,
                          display: "inline-block",
                          whiteSpace: "nowrap",
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textTransform: "capitalize",
                        }}
                      >
                        {category}
                      </span>
                    </div>
                    <ul
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        color: "content-secondary",
                        m: 0,
                        p: 0,
                        listStyle: "none",
                        rowGap: "spacing-s",
                      }}
                    >
                      {feedback.map((item, index) => (
                        <li
                          key={item.topic + index}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <span>{item.topic}</span>
                          {index !== feedback.length - 1 && (
                            <DotIcon sx={{ marginX: "13px" }} />
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>

            <p
              sx={{
                color: "content-secondary",
                fontSize: 14,
                fontWeight: 500,
                marginTop: "24px",
              }}
            >
              {review?.comments}
            </p>
          </div>
        </div>
        {review.ride ? (
          <div
            sx={{
              color: "black",
              fontSize: 14,
              fontWeight: 500,
              border: "1px solid rgba(217, 219, 233, 1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 4,
              padding: "8px 12px",
              borderRadius: "8px",
              cursor: "pointer",
              width: 140,
            }}
            onClick={() =>
              window.open(`/rides-management/rides/${review?.ride}`, "_blank")
            }
          >
            <RideIcon />
            <p sx={{ margin: 0, fontSize: 14, fontWeight: 500 }}>
              {t("rating-reviews.view-ride")}
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ReviewItem;
