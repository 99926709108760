/** @jsxImportSource theme-ui */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import once from "lodash/once";
import GA from "react-ga";

import {
  useListVehicles,
  ListVehicleData,
  Vehicle,
} from "resources/vehicles-list/useListVehicle";
import { useQueryCaptain } from "resources/common/useQueryCaptain";
import useFilters from "hooks/useFilters";
import { Captain } from "utils/types";

import VehicleListing from "./VehicleListing";
import NotFound from "components/NotFound";
import Autocomplete from "components/AutocompleteWrapper";

import trackEvents from "constants/trackEvents";

const events = trackEvents.getVehicleEvents();

const trackScreenVehiclesList = once((number_of_vehicles: number) => {
  GA.event({
    ...events.screenVehicleList,
    label: JSON.stringify({ number_of_vehicles }),
  });
});

const Vehicles = () => {
  const { t } = useTranslation("vehicles");
  const [vehicleDetailErrorStatus, setVehicleDetailErrorStatus] =
    useState<boolean>(false);
  const [initialCaptainValue, setInitialCaptainValue] = useState("");
  const navigate = useNavigate();
  const {
    mutate: queryCaptain,
    data: captainSuggestions = [],
    isLoading: isCaptainQueryLoading,
  } = useQueryCaptain();

  const [filters, dispatchFilters, filtersString] = useFilters<ListVehicleData>(
    {
      limit: 10,
      page: 1,
    }
  );
  const sanitizedFilers = { ...filters };

  if (sanitizedFilers.captain_id) {
    const [_id] = sanitizedFilers.captain_id.split("-");
    sanitizedFilers.captain_id = _id;
  }

  const { data: vehicles, isLoading: isVehiclesListLoading } =
    useListVehicles(sanitizedFilers);

  const onRowClick = (vehicle: Vehicle | undefined) => {
    if (!vehicle) return;

    navigate(vehicle._id);
    GA.event(events.actionOpenVehicleInfo);
  };

  useEffect(() => {
    if (filters.captain_id) {
      const [, captainName] = filters.captain_id.split("-");
      setInitialCaptainValue(captainName);
    }
  }, []);

  useEffect(() => {
    if (!isVehiclesListLoading && vehicles) {
      trackScreenVehiclesList(vehicles.hits.length);

      if (vehicles.hits.length === 0 && filters.page > 1) {
        dispatchFilters({ type: "UPDATE", filters: { page: 1 } });
      }
    }
  }, [isVehiclesListLoading, vehicles]);

  return (
    <div
      sx={{
        px: "spacing-m",
        pt: "spacing-m",
      }}
    >
      <Helmet>
        <title>
          {t("title")} | {t("dashboard-title", { ns: "common" })}
        </title>
      </Helmet>
      {vehicleDetailErrorStatus ? (
        <NotFound
          title={t("not-found-title", { ns: "common" })}
          description={t("not-found-description", { ns: "common" })}
          goBackCallback={() => {
            setVehicleDetailErrorStatus(false);
            navigate(`/fleet/vehicles?${filtersString}`, { replace: true });
          }}
        />
      ) : (
        <>
          <h4 sx={{ variant: "text.h4", m: 0 }}>{t("title")}</h4>
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              my: "spacing-m",
            }}
          >
            <Autocomplete
              required // To hide the optional word in label
              label={t("search")}
              onFocus={() => GA.event(events.actionEnterSearch)}
              onChangeQuery={(query: string) => {
                GA.event({
                  ...events.actionSubmitSearch,
                  label: JSON.stringify({
                    search_key: query,
                    type: "vehicles",
                  }),
                });
                queryCaptain(query);
              }}
              suggestions={captainSuggestions}
              isLoading={isCaptainQueryLoading}
              initialValue={initialCaptainValue}
              onOptionSelect={(captain) => {
                const { name, _id } = captain as Captain;
                dispatchFilters({
                  type: "UPDATE",
                  filters: {
                    captain_id: `${_id}-${name}`,
                    page: 1,
                  },
                });

                GA.event(events.actionFilterSearch);
              }}
              onClear={() => {
                dispatchFilters({
                  type: "UPDATE",
                  filters: { captain_id: undefined, page: 1 },
                });

                GA.event(events.actionFilterSearch);
              }}
              placeholder={t("search-captain")}
              height="compact"
              noResultsMessage={t("no-result-found", { ns: "common" })}
            />
          </div>

          <VehicleListing
            isLoading={isVehiclesListLoading}
            vehicles={vehicles}
            filters={filters}
            dispatchFilters={dispatchFilters}
            onRowClick={onRowClick}
          />
        </>
      )}
    </div>
  );
};

export default Vehicles;
