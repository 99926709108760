/** @jsxImportSource theme-ui */

import { useState, useEffect } from "react";
import {
  add,
  format,
  startOfToday,
  endOfToday,
  startOfTomorrow,
  endOfTomorrow,
  startOfDay,
  endOfDay,
  isToday,
  isTomorrow,
} from "date-fns";
import { useTranslation } from "react-i18next";
import { CalendarIcon, LineIcon, AcuteClockIcon } from "@swvl/icons";
import DatePicker from "@swvl/date-picker";
import { TimeRangePicker } from "@swvl/time-picker";
import { ButtonGroup, ButtonWrapper } from "@swvl/button-group";
import { Button } from "@swvl/button";
import { dateFormatWithYear } from "constants/index";
import { getZeroPaddedTime } from "utils";

let isCustomPristine = true;
let isCustomTimePristine = true;
const DEFAULT_TIME_RANGE = "00:00-00:00";
const GroupDatePicker = ({
  label,
  startDate,
  endDate,
  onDateChange,
}: {
  label?: string;
  startDate: string;
  endDate: string;
  onDateChange: (startDate: Date, endDate: Date, type: string) => void;
}) => {
  const { t } = useTranslation("common");
  const [lastActiveDateOption, setLastActiveDateOption] =
    useState<string>("today");
  const [activeDateOption, setActiveDateOption] = useState<string>("today");
  const [customStartDate, setCustomStartDate] = useState<Date | null>(
    new Date(startDate)
  );
  const [customEndDate, setCustomEndDate] = useState<Date | null>(
    new Date(endDate)
  );
  const [customTimeRange, setCustomTimeRange] =
    useState<string>(DEFAULT_TIME_RANGE);
  const [shouldApplyEndDate, setShouldApplyEndDate] = useState(false);

  const onCustomDateChange = (dates: [Date, Date | null]) => {
    const [start, end] = dates;
    const startOfStartDate = startOfDay(start);
    const endOfEndDate = end ? endOfDay(end) : null;

    setCustomStartDate(startOfStartDate);
    setCustomEndDate(endOfEndDate);

    if (startOfStartDate && endOfEndDate) {
      setLastActiveDateOption("custom");
      isCustomPristine = false;
    }
  };

  useEffect(() => {
    if (!isCustomTimePristine) {
      const sTime = getZeroPaddedTime(customStartDate);
      const eTime = getZeroPaddedTime(customEndDate);

      setCustomTimeRange(`${sTime}-${eTime}`);
    }
  }, []);

  useEffect(() => {
    if (isToday(new Date(startDate)) && isToday(new Date(endDate))) {
      setActiveDateOption("today");
      setLastActiveDateOption("today");
    } else if (
      isTomorrow(new Date(startDate)) &&
      isTomorrow(new Date(endDate))
    ) {
      setActiveDateOption("tomorrow");
      setLastActiveDateOption("tomorrow");
    } else {
      setActiveDateOption("custom");
      setLastActiveDateOption("custom");
    }

    setCustomStartDate(new Date(startDate));
    setCustomEndDate(new Date(endDate));
  }, [startDate, endDate]);

  const onChange = (type: string) => {
    if (type === "today") {
      setActiveDateOption("today");
      setLastActiveDateOption("today");
      onDateChange(startOfToday(), endOfToday(), "today");

      // Reset custom
      isCustomPristine = true;
    } else if (type === "tomorrow") {
      setActiveDateOption("tomorrow");
      setLastActiveDateOption("tomorrow");
      onDateChange(startOfTomorrow(), endOfTomorrow(), "tomorrow");

      // Reset custom
      isCustomPristine = true;
    } else if (type === "custom") {
      setActiveDateOption("custom");
    }
  };

  const onCalendarClose = () => {
    if (!customEndDate) {
      setCustomStartDate(new Date(startDate));
      setCustomEndDate(new Date(endDate));
    }

    if (isCustomPristine) {
      setActiveDateOption(lastActiveDateOption);
    }
  };

  const handleApplyDateRange = () => {
    if (!customStartDate || !customEndDate) return;

    if (isCustomTimePristine) {
      onDateChange(customStartDate, customEndDate, "custom");
    } else {
      const [startTime, endTime]: string[] = customTimeRange.split("-");

      const [sHours, sMinutes] = startTime.split(":");
      const [eHours, eMinutes] = endTime.split(":");

      const newStartDate = new Date(customStartDate.toString());
      const newEndDate = new Date(customEndDate.toString());

      newStartDate.setHours(Number(sHours), Number(sMinutes));
      newEndDate.setHours(Number(eHours), Number(eMinutes));
      onDateChange(newStartDate, newEndDate, "custom");
    }
  };
  return (
    <div sx={{ width: "fit-content" }}>
      {label && (
        <div
          sx={{
            variant: "text.p-medium",
            marginBottom: "spacing-xs",
          }}
        >
          {label}
        </div>
      )}
      <ButtonGroup>
        <ButtonWrapper
          title="today"
          iconPosition="start"
          icon={
            <CalendarIcon width="20px" height="20px" color="content-primary" />
          }
          onClick={() => onChange("today")}
          active={activeDateOption === "today"}
        >
          {t("today")}
        </ButtonWrapper>
        <ButtonWrapper
          title="tomorrow"
          active={activeDateOption === "tomorrow"}
          onClick={() => onChange("tomorrow")}
        >
          {t("tomorrow")}
        </ButtonWrapper>
        <DatePicker
          onChange={onCustomDateChange}
          onCalendarClose={onCalendarClose}
          startDate={customStartDate}
          endDate={customEndDate}
          selectsRange
          shouldCloseOnSelect={false}
          monthsShown={2}
          onDayMouseEnter={() => {
            setShouldApplyEndDate(true);
          }}
          onInputClick={() => onChange("custom")}
          maxDate={
            customStartDate && !customEndDate && shouldApplyEndDate
              ? add(new Date(customStartDate), { days: 6 })
              : undefined
          }
          customInput={
            <ButtonWrapper
              title="custom"
              active={activeDateOption === "custom"}
            >
              {activeDateOption === "custom" && customStartDate && customEndDate
                ? `${format(new Date(customStartDate), dateFormatWithYear)} ${
                    customEndDate
                      ? `- ${format(customEndDate, dateFormatWithYear)}`
                      : ""
                  }`
                : t("custom")}
            </ButtonWrapper>
          }
        >
          <div
            sx={{ position: "relative", display: "flex", alignItems: "center" }}
          >
            <AcuteClockIcon sx={{ marginInlineEnd: "spacing-xxs" }} />
            <p sx={{ marginInlineEnd: "spacing-xs", variant: "text.p-small" }}>
              {t("time")}
            </p>
            <TimeRangePicker
              required
              searchable
              selectOnFocus
              timeFormate="24hr"
              dropdownListTrigger="input"
              iconPosition="none"
              value={customTimeRange as string}
              minutesStep={5}
              onChange={(time) => {
                if (isCustomTimePristine && time !== DEFAULT_TIME_RANGE) {
                  isCustomTimePristine = false;
                }
                setCustomTimeRange(time);
              }}
              height="compact"
              divider={
                <LineIcon
                  width={17}
                  sx={{
                    marginX: "spacing-xs",
                  }}
                />
              }
            />
            <Button
              onClick={handleApplyDateRange}
              sx={{
                position: "absolute",
                insetInlineEnd: 0,
                paddingInlineStart: "12px",
                paddingInlineEnd: "spacing-s",
              }}
            >
              {t("apply")}
            </Button>
          </div>
        </DatePicker>
      </ButtonGroup>
    </div>
  );
};

export default GroupDatePicker;
