/** @jsxImportSource theme-ui */
import { Fragment, Children, isValidElement, FC } from "react";
import { BoxProps } from "theme-ui";

interface InputGroupProps extends BoxProps {
  label?: string;
}

const InputGroup: FC<InputGroupProps> = ({ children, ...props }) => {
  const childrenArray = Children.toArray(children);

  return (
    <div>
      {props?.label && (
        <div
          sx={{
            variant: "text.p-medium",
            marginBottom: "spacing-xs",
          }}
        >
          {props.label}
        </div>
      )}
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid",
          borderColor: "border",
          borderRadius: 6,
          p: 1,

          "> *:not(.divider)": {
            flex: 1,
          },
        }}
        {...props}
      >
        {childrenArray.map((child, index) => {
          if (!isValidElement(child)) return null;
          const isLast = index === childrenArray.length - 1;

          return (
            <Fragment key={`input-${index}`}>
              {child}
              {!isLast && (
                <div
                  className="divider"
                  sx={{
                    width: 1,
                    height: "calc(100% - 14px)",
                    backgroundColor: "content-quarternary",
                    mx: 1,
                  }}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default InputGroup;
