/** @jsxImportSource theme-ui */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect, Select } from "@swvl/select";
import { CloseIcon, FilterIcon } from "@swvl/icons";
import { Button } from "@swvl/button";
import GA from "react-ga";

import trackEvents from "constants/trackEvents";

import {
  ListCaptainFilters,
  ListCaptainParams,
} from "resources/captain-list/useListCaptain";

import { FilterAction } from "hooks/useFilters";
import { getStatusesData } from "pages/fleet/utils";
import Autocomplete from "components/AutocompleteWrapper";
import { AutoCompleteOption, Captain, Option } from "utils/types";

const CaptainsListMoreFilter = ({
  cancel,
  filters,
  dispatchFilters: updateFilters,
  captainSuggestions,
  queryCaptain,
  isCaptainQueryLoading,
}: {
  cancel: () => void;
  filters: ListCaptainParams;
  dispatchFilters: React.Dispatch<FilterAction<ListCaptainParams>>;
  captainSuggestions: AutoCompleteOption[];
  queryCaptain: (query: string) => void;
  isCaptainQueryLoading: boolean;
  captains?: ListCaptainParams;
}) => {
  const { t } = useTranslation("captains");
  const events = trackEvents.getCaptainEvents();
  const [captainFilters, setCaptainFilters] = useState<ListCaptainParams>({
    document_verification_status:
      filters.document_verification_status || undefined,
    status: filters.status || undefined,
    page: filters.page || 1,
    limit: filters.limit || 10,
    score: filters.score || undefined,
    captain: filters.captain || undefined,
  });

  const captainStatusOptions = [
    { value: undefined, label: t("captain-statuses.all") },
    { value: "active", label: t("captain-statuses.active") },
    {
      value: "inactive",
      label: t("captain-statuses.inactive"),
    },
  ];
  const documentVerificationOptions = [
    { value: "verified", label: t("document-verification-statuses.verified") },
    { value: "pending", label: t("document-verification-statuses.pending") },
    {
      value: "expired",
      label: t("document-verification-statuses.expired"),
    },
    {
      value: "expiring_soon",
      label: t("document-verification-statuses.expiring_soon"),
    },
  ];

  const captainRatingOptions = [
    { value: "1", label: t("captain-rating-options.one-star") },
    { value: "2", label: t("captain-rating-options.two-stars") },
    {
      value: "3",
      label: t("captain-rating-options.three-stars"),
    },
    {
      value: "4",
      label: t("captain-rating-options.four-stars"),
    },
    {
      value: "5",
      label: t("captain-rating-options.five-stars"),
    },
  ];

  const updateCurrentFilters = (filters: ListCaptainFilters) => {
    const updatedFilters = {
      ...captainFilters,
      ...filters,
      page: 1,
    };
    setCaptainFilters(updatedFilters);
  };

  const applyFilters = () => {
    updateFilters({
      type: "UPDATE",
      filters: captainFilters,
    });
    cancel();
  };
  const clearFilters = () => {
    updateFilters({
      type: "UPDATE",
      filters: {
        status: undefined,
        document_verification_status: undefined,
        page: 1,
        limit: 10,
        score: undefined,
        captain: undefined,
      },
    });
    cancel();
  };

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "spacing-m-l",
          width: "90%",
        }}
      >
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "spacing-s",
            }}
          >
            <FilterIcon />
            <h2>{t("filter-by")}</h2>
          </div>
          <CloseIcon cursor="pointer" onClick={cancel} />
        </div>
      </div>
      <div
        sx={{
          width: "90%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: "spacing-m",
        }}
      >
        <div>
          <Autocomplete
            onFocus={() => GA.event(events.actionEnterSearch)}
            minCharacters={1}
            suggestions={captainSuggestions}
            label={t("search")}
            height="compact"
            required
            value={captainFilters.captain}
            isLoading={isCaptainQueryLoading}
            noResultsMessage={t("no-result-found", { ns: "common" })}
            onChangeQuery={(query: string) => {
              GA.event({
                ...events.actionSubmitSearch,
                label: JSON.stringify({
                  search_key: query,
                  type: "captains",
                }),
              });
              if (query.length > 3) queryCaptain(query);
            }}
            onOptionSelect={(captain) => {
              const { _id } = captain as Captain;
              updateCurrentFilters({ captain: [_id] });
            }}
            placeholder={t("search-captain")}
          />
        </div>
        <div>
          <Select
            label={t("captain-status-label")}
            required // to remove the (optional word in label)
            id="more-filters-captain-status"
            name="more-filters-captain-status"
            height="compact"
            options={captainStatusOptions}
            value={captainStatusOptions.find(
              ({ value }) => captainFilters.status === value
            )}
            isClearable={false}
            onChange={(value) => {
              const option = value as Option;
              if (option.value === "all") return;

              updateCurrentFilters({ status: option.value as string });
            }}
            placeholder={t("captain-statuses.placeholder")}
            sx={{ flex: 1 }}
          />
        </div>
        <div>
          <MultiSelect
            label={t("captain-rating")}
            required
            id="more-filters-captain-verification-status"
            name="captainVerificationStatus"
            height="compact"
            options={captainRatingOptions}
            placeholder={t("captain-rating-options-placeholder")}
            value={getStatusesData(
              captainRatingOptions,
              captainFilters.score || []
            )}
            onChange={(values) => {
              const score = (values as Option[]).reduce(
                (allScores: string[], { value: score }) => {
                  return [...allScores, ...(score as string).split(",")];
                },
                []
              );
              updateCurrentFilters({ score: score });
              GA.event({
                ...events.actionFilterCaptainRating,
                label: JSON.stringify({ score: score }),
              });
            }}
            sx={{ flex: 1 }}
          />
        </div>
        <div>
          <MultiSelect
            label={t("captain-documents-status")}
            required // to remove the (optional word in label)
            id="more-filters-captain-verification-status"
            name="captainVerificationStatus"
            height="compact"
            options={documentVerificationOptions}
            value={getStatusesData(
              documentVerificationOptions,
              captainFilters.document_verification_status || []
            )}
            onChange={(values) => {
              const status = (values as Option[]).reduce(
                (allStatuses: string[], { value: status }) => {
                  return [...allStatuses, ...(status as string).split(",")];
                },
                []
              );
              updateCurrentFilters({ document_verification_status: status });
              GA.event({
                ...events.actionFilterCaptainVerificationStatus,
                label: JSON.stringify({ document_verification_status: status }),
              });
            }}
            placeholder={t("document-verification-statuses.placeholder")}
            sx={{ flex: 1 }}
          />
        </div>
      </div>

      <div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: "70px",
          width: "100%",
          padding: "0 5%",
          borderTop: "1px solid",
          borderColor: "border",
        }}
      >
        <Button
          variant="text_link"
          onClick={clearFilters}
          sx={{
            paddingInlineStart: "12px",
          }}
        >
          {t("clear-all")}
        </Button>
        <Button
          onClick={applyFilters}
          sx={{
            paddingInlineStart: "12px",
            paddingInlineEnd: "spacing-s",
          }}
        >
          {t("apply")}
        </Button>
      </div>
    </div>
  );
};

export default CaptainsListMoreFilter;
