/** @jsxImportSource theme-ui */

import Table from "@swvl/table";
import useLang from "hooks/useLang";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { Column } from "react-table";
import { useCaptainsBuses } from "resources/captain-list/useCaptainsBuses";
import { CaptainVehicle } from "utils/types";
import NoData from "components/NoData";
import emptyBoxLogo from "assets/vehicles-empty-box.svg";

const CaptainVehicles = () => {
  const { t } = useTranslation("captains");
  const [lang] = useLang();
  const { id: captainId } = useParams();
  const { data: captainBuses, isLoading } = useCaptainsBuses(captainId || "");
  const navigate = useNavigate();

  const columns: Column<CaptainVehicle>[] = [
    {
      Header: `${t("vehicle-details.plate-number")}`,
      accessor: "plates",
      id: "vehicle-plate",
      Cell: ({ value }) => value,
    },
    {
      Header: `${t("vehicle-details.make")}`,
      accessor: "make",
      id: "vehicle-make",
      Cell: () => <>-</>,
    },
    {
      Header: `${t("vehicle-details.type")}`,
      accessor: "type",
      id: "vehicle-type",
      Cell: ({ value }) => value?.name || "-",
    },
    {
      Header: `${t("vehicle-details.year")}`,
      id: "vehicle-year",
      accessor: "year",
      Cell: () => <>-</>,
    },
    {
      Header: `${t("vehicle-details.color")}`,
      id: "vehicle-color",
      accessor: "color",
      Cell: () => <>-</>,
    },
    {
      Header: `${t("verification-status")}`,
      id: "verification-status",
      accessor: "document_verification_status",
      Cell: () => <>-</>,
    },
  ];

  const getRowProps = useCallback(
    (props, original: CaptainVehicle | undefined) => {
      return {
        ...props,
        onClick: (e: MouseEvent) => {
          e.preventDefault();
          navigate(`/fleet/vehicles/${original?._id}`);
        },
        sx: {
          cursor: "pointer",
        },
      };
    },
    []
  );

  if (!captainBuses?.hits.length) {
    return (
      <NoData
        title={t("vehicle-details.no-data-title")}
        description={t("vehicle-details.no-data-description")}
        image={emptyBoxLogo}
      />
    );
  }
  return (
    <div
      sx={{
        paddingTop: 20,
      }}
    >
      <Table
        data={captainBuses?.hits || []}
        columns={columns}
        fullWidth={true}
        sameSizeCells={false}
        locale={t("table-locales", { ns: "common", returnObjects: true })}
        direction={lang.direction}
        isLoading={isLoading}
        getRowProps={getRowProps}
      />
    </div>
  );
};

export default CaptainVehicles;
