/** @jsxImportSource theme-ui */
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import once from "lodash/once";
import _ from "lodash";
import { Button } from "@swvl/button";
import Drawer from "@swvl/drawer";
import { CaptainAddIcon } from "@swvl/icons";
import { Select } from "@swvl/select";
import { showAlert } from "@swvl/alert";

import GA from "react-ga";

import Autocomplete from "components/AutocompleteWrapper";
import NotFound from "components/NotFound";
import InviteCaptain from "./InviteCaptain";
import CaptainListing from "./CaptainListing";
import CaptainsListMoreFilter from "./CaptainsListMoreFilter";

import trackEvents from "constants/trackEvents";
import { numbersRegExp, arabicNumbersRegExp } from "constants/index";
import useFilters from "hooks/useFilters";
import useSelectedVendor from "hooks/useSelectedVendor";
import {
  useListCaptains,
  ListCaptainParams,
} from "resources/captain-list/useListCaptain";
import { useQueryCaptain, Option } from "resources/common/useQueryCaptain";

import { getCountryCallingCodeString } from "utils";
import { Captain } from "utils/types";
import { getStatusesData } from "./utils";
import AppliedFilters from "components/AppliedFilters";
import ToolbarButton from "shared/components/ToolbarButton";
import FilterIcon from "shared/icons/FilterIcon";

const events = trackEvents.getCaptainEvents();
const trackScreenCaptainsList = once((number_of_captains: number) => {
  GA.event({
    ...events.screenCaptainsList,
    label: JSON.stringify({ number_of_captains }),
  });
});

const Captains = () => {
  const { t } = useTranslation("captains");
  const navigate = useNavigate();
  const [isInviteCaptainDrawerOpen, setInviteCaptainDrawerOpen] =
    useState(false);
  const [isCaptainsFiltersDrawerOpen, setCaptainsFiltersDrawerOpen] =
    useState(false);
  const [captainDetailErrorStatus, setCaptainDetailErrorStatus] =
    useState<boolean>(false);
  const [shouldShowStartEnhancer, setShouldShowStartEnhancer] =
    useState<boolean>(false);
  const [filters, dispatchFilters, filterString] =
    useFilters<ListCaptainParams>({
      limit: 10,
      page: 1,
    });
  const { data: captains, isLoading: isCaptainListLoading } =
    useListCaptains(filters);
  const selectedVendor = useSelectedVendor();

  useEffect(() => {
    if (!isCaptainListLoading && captains) {
      trackScreenCaptainsList(captains.hits.length);

      if (captains.hits.length === 0 && filters.page > 1) {
        dispatchFilters({ type: "UPDATE", filters: { page: 1 } });
      }
    }
  }, [isCaptainListLoading, captains]);

  const appliedVerificationStatusData = () => {
    return getStatusesData(
      documentVerificationOptions,
      filters.document_verification_status || []
    ).map((status) => status.label);
  };

  const captainFilter = useMemo(
    () => captains?.hits.find((cap) => filters.captain?.includes(cap._id)),
    [captains?.hits, filters.captain]
  );

  const getAppliedFilters = () => {
    return {
      ...(filters?.status?.length && {
        status: {
          title: t("status"),
          value: [t(`captain-statuses.${filters.status}`)],
        },
      }),
      ...(filters?.document_verification_status?.length && {
        document_verification_status: {
          title: t("verification-status"),
          value: appliedVerificationStatusData(),
        },
      }),
      ...(filters?.score?.length && {
        score: {
          title:
            filters.score.includes("1") && filters.score.length === 1
              ? t("captain-star")
              : t("captain-stars"),
          value: [filters.score],
        },
      }),
      ...(filters?.captain?.length && {
        captain: {
          title: t("captain"),
          value: captainFilter?.name || "",
        },
      }),
    };
  };

  const clearAppliedFilters = () => {
    dispatchFilters({
      type: "UPDATE",
      filters: {
        page: 1,
        limit: 10,
        status: undefined,
        document_verification_status: undefined,
        score: undefined,
        captain: undefined,
      },
    });
  };

  const getVerificationStatusFilterValue = (label: string) =>
    documentVerificationOptions.find((status) => status.label === label)?.value;

  type FindDeletedMultiSelectOption = {
    [key: string]: (label: string) => string | undefined;
  };
  const findDeletedMultiSelectOptionFns: FindDeletedMultiSelectOption = {
    document_verification_status: getVerificationStatusFilterValue,
  };

  const removeFromMultiSelectFilter = ({
    filterName,
    toBeDeletedValue,
  }: {
    filterName: string;
    toBeDeletedValue: string;
  }) => {
    let multiSelectFilter = filters[filterName as keyof ListCaptainParams]
      ? filters[filterName as keyof ListCaptainParams]
      : undefined;
    const toBeRemovesFilterValues =
      findDeletedMultiSelectOptionFns[
        filterName as keyof FindDeletedMultiSelectOption
      ]?.(toBeDeletedValue)?.split(",");

    if (
      !multiSelectFilter ||
      !Array.isArray(multiSelectFilter) ||
      !toBeRemovesFilterValues
    )
      return;

    multiSelectFilter = multiSelectFilter.filter(
      (item) => !toBeRemovesFilterValues.includes(item)
    );

    dispatchFilters({
      type: "UPDATE",
      filters: {
        ...filters,
        [filterName]: [...multiSelectFilter],
      },
    });
  };

  const removeFilter = ({
    key: filterName,
    value: toBeDeletedValue,
  }: {
    key: string;
    value: string;
  }) => {
    if (filterName === "document_verification_status") {
      removeFromMultiSelectFilter({ filterName, toBeDeletedValue });
    } else {
      dispatchFilters({
        type: "UPDATE",
        filters: {
          ...filters,
          [filterName]: undefined,
        },
      });
    }
  };

  const {
    mutate: queryCaptain,
    data: captainSuggestions = [],
    isLoading: isCaptainQueryLoading,
  } = useQueryCaptain();

  const onCaptainSelect = (id: string) => {
    navigate(id);
  };

  const onRowClick = (captain: Captain | undefined) => {
    if (!captain) return;

    navigate(captain._id);
  };

  const captainStatusOptions = [
    { value: undefined, label: t("captain-statuses.all") },
    { value: "active", label: t("captain-statuses.active") },
    {
      value: "inactive",
      label: t("captain-statuses.inactive"),
    },
  ];
  const documentVerificationOptions = [
    { value: "verified", label: t("document-verification-statuses.verified") },
    { value: "pending", label: t("document-verification-statuses.pending") },
    {
      value: "expired",
      label: t("document-verification-statuses.expired"),
    },
    {
      value: "expiring_expiring_soon",
      label: t("document-verification-statuses.expiring_soon"),
    },
  ];

  const isPhoneSearch = (queryStr: string): boolean => {
    return !!(
      queryStr.match(numbersRegExp) || queryStr.match(arabicNumbersRegExp)
    );
  };

  const preparePhoneOrNameSearchQuery = (query: string) => {
    const queryStr: string = query.replace(/^0+/, "");

    if (isPhoneSearch(queryStr))
      return `${getCountryCallingCodeString(
        selectedVendor.country_code
      )}${queryStr}`;
    return queryStr;
  };

  const onValueChange = (query: string) => {
    const queryStr: string = query.replace(/^0+/, "");
    if (!query) setShouldShowStartEnhancer(false);
    else setShouldShowStartEnhancer(isPhoneSearch(queryStr));
  };

  return (
    <div
      sx={{
        px: "spacing-m",
        pt: "spacing-m",
      }}
    >
      <Helmet>
        <title>
          {t("title")} | {t("dashboard-title", { ns: "common" })}
        </title>
      </Helmet>

      {captainDetailErrorStatus ? (
        <NotFound
          title={t("not-found-title", { ns: "common" })}
          description={t("not-found-description", { ns: "common" })}
          goBackCallback={() => {
            setCaptainDetailErrorStatus(false);
            navigate(`/fleet/captains?${filterString}`, { replace: true });
          }}
        />
      ) : (
        <>
          <h4 sx={{ variant: "text.h4", m: 0 }}>{t("title")}</h4>
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
              my: "spacing-m",
            }}
          >
            <div
              sx={{
                display: "flex",
                gap: "spacing-s",
                alignItems: "end",
              }}
            >
              <Autocomplete
                onFocus={() => GA.event(events.actionEnterSearch)}
                minCharacters={1}
                height="compact"
                onChange={(e) => onValueChange(e.target.value)}
                required // To hide the optional word in label
                label={t("search")}
                onChangeQuery={(query: string) => {
                  GA.event({
                    ...events.actionSubmitSearch,
                    label: JSON.stringify({
                      search_key: query,
                      type: "captains",
                    }),
                  });
                  const queryStr: string = preparePhoneOrNameSearchQuery(query);
                  if (queryStr.length > 3) queryCaptain(queryStr);
                }}
                suggestions={captainSuggestions}
                isLoading={isCaptainQueryLoading}
                onOptionSelect={(captain) => {
                  const { _id } = captain as Captain;
                  onCaptainSelect(_id);
                  setShouldShowStartEnhancer(false);
                }}
                startEnhancer={
                  shouldShowStartEnhancer
                    ? getCountryCallingCodeString(selectedVendor.country_code)
                    : ""
                }
                placeholder={t("search-captain")}
                noResultsMessage={t("no-result-found", { ns: "common" })}
                onClear={() => setShouldShowStartEnhancer(false)}
              />

              <div sx={{ flex: 1 }}>
                <Select
                  label={t("captain-status-label")}
                  required // to remove the (optional word in label)
                  id="more-filters-captain-status"
                  name="more-filters-captain-status"
                  height="compact"
                  options={captainStatusOptions}
                  value={captainStatusOptions.find(
                    ({ value }) => filters.status === value
                  )}
                  isClearable={false}
                  onChange={(value) => {
                    const option = value as Option;
                    dispatchFilters({
                      type: "UPDATE",
                      filters: {
                        status: option.value as string,
                        page: 1,
                      },
                    });
                  }}
                  placeholder={t("captain-statuses.placeholder")}
                  sx={{ flex: 1, minWidth: "180px" }}
                />
              </div>
              <ToolbarButton
                title={t("more-filters")}
                icon={<FilterIcon />}
                onClick={() => {
                  setCaptainsFiltersDrawerOpen(true);
                  GA.event(events.actionOpenCaptainMoreFilters);
                }}
              />
            </div>
            <Button
              iconPosition="start"
              icon={<CaptainAddIcon />}
              onClick={() => {
                if (selectedVendor.country_code) {
                  setInviteCaptainDrawerOpen(true);
                  GA.event(events.actionOpenInviteCaptain);
                } else {
                  showAlert({
                    message: t("no-vendor-country", {
                      vendorName: selectedVendor.name,
                    }),
                    variant: "error",
                    mode: "light",
                  });
                }
              }}
              sx={{
                paddingInlineStart: "12px",
                paddingInlineEnd: "spacing-s",
              }}
            >
              {t("invite-captain-button")}
            </Button>
          </div>

          {_.keys(getAppliedFilters()).length ? (
            <AppliedFilters
              title={t("applied-filters")}
              filters={getAppliedFilters()}
              clearAll={clearAppliedFilters}
              removeFilter={removeFilter}
            />
          ) : null}

          <CaptainListing
            captains={captains}
            isLoading={isCaptainListLoading}
            filters={filters}
            dispatchFilters={dispatchFilters}
            onRowClick={onRowClick}
          />
        </>
      )}

      <Drawer
        isOpen={isInviteCaptainDrawerOpen}
        closeDrawer={() => {
          setInviteCaptainDrawerOpen(false);
          GA.event(events.actionCloseInviteCaptain);
        }}
        size={"large"}
      >
        <InviteCaptain
          setInviteCaptainDrawerOpen={setInviteCaptainDrawerOpen}
          selectedVendor={selectedVendor}
        />
      </Drawer>
      <Drawer
        isOpen={isCaptainsFiltersDrawerOpen}
        closeDrawer={() => {
          setCaptainsFiltersDrawerOpen(false);
          GA.event(events.actionCloseCaptainMoreFilters);
        }}
        size={"medium"}
      >
        <CaptainsListMoreFilter
          cancel={() => {
            setCaptainsFiltersDrawerOpen(false);
            GA.event(events.actionCloseCaptainMoreFilters);
          }}
          filters={filters}
          dispatchFilters={dispatchFilters}
          captainSuggestions={captainSuggestions}
          queryCaptain={queryCaptain}
          isCaptainQueryLoading={isCaptainQueryLoading}
        />
      </Drawer>
    </div>
  );
};

export default Captains;
