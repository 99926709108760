/** @jsxImportSource theme-ui */
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import startCase from "lodash/startCase";
import { Spinner } from "theme-ui";
import Avatar from "@swvl/avatar";
import GA from "react-ga";

import { InfoWrapper, InfoWrapperItem } from "utils/styled";
import AvatarDefault from "assets/avatar-default.png";

import { useVehicleDetails } from "resources/vehicles-list/useVehicleDetail";
import trackEvents from "constants/trackEvents";
import NotFound from "components/NotFound";
import { BAD_REQUEST, NOT_FOUND } from "constants/httpCode";
import { IconButton } from "@swvl/button";
import { useTheme } from "@swvl/theme";
import useLang from "hooks/useLang";
import { ArrowLeftIcon, ArrowRightIcon } from "@swvl/icons";
import TabsWrapper, { TabWrapper } from "@swvl/tabs";
import VerifiableDocuments from "./VerifiableDocuments";
import EmptyBoxLogo from "assets/logo-empty-box.svg";

const VehicleDetails = () => {
  const { t } = useTranslation("vehicles");
  const { id: vehicleId } = useParams();
  const {
    data: vehicle,
    isLoading: isVehicleDetailLoading,
    error,
  } = useVehicleDetails(vehicleId);
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [lang] = useLang();
  const events = trackEvents.getVehicleEvents();

  useEffect(() => {
    GA.event(events.screenVehicleInfo);
  }, []);

  const goBack = () => navigate("/fleet/vehicles", { replace: true });
  if (error) {
    const { status } = error;

    if (status === BAD_REQUEST || status === NOT_FOUND) {
      return (
        <NotFound
          title={t("not-found-title", { ns: "common" })}
          description={t("not-found-description", { ns: "common" })}
          goBackCallback={goBack}
        />
      );
    }
  }

  if (isVehicleDetailLoading) {
    return (
      <div
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Spinner color="secondary" />{" "}
      </div>
    );
  }

  if (!vehicle) return null;

  return (
    <div>
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "spacing-m",
          background: "linear-gradient(0deg, #FFF 0%, #F4F7FF 100%)",
        }}
      >
        <IconButton
          sx={{
            marginInlineEnd: "spacing-m",
            "& svg": { fill: theme.colors["gray-700"] },
          }}
          icon={lang.code === "ar" ? <ArrowRightIcon /> : <ArrowLeftIcon />}
          onClick={goBack}
        />

        <Avatar
          name="Vehicle Picture"
          src={vehicle.type?.picture || AvatarDefault}
          sx={{
            width: "58.7px",
            height: "58.7px",
            marginInlineEnd: "spacing-m",
          }}
        />
        <h5
          title={vehicle?.plates}
          sx={{
            variant: "text.h4",
            m: 0,
          }}
        >
          {vehicle?.plates}
        </h5>
      </div>

      <div
        sx={{
          marginTop: "spacing-s",
          px: "spacing-m",
          pt: "spacing-m",
        }}
      >
        <TabsWrapper tabSize="medium">
          <TabWrapper tab={t("info")} key={"1"}>
            <div
              sx={{
                paddingTop: "spacing-m",
                overflow: "auto",
                flexGrow: "1",
              }}
            >
              <InfoWrapper>
                <InfoWrapperItem compact>
                  <p>{t("bus-type")}</p>
                  <p>{startCase(vehicle.type?.name)}</p>
                </InfoWrapperItem>
                <InfoWrapperItem compact>
                  <p>{t("captain-assigned")}</p>
                  <p>
                    {vehicle.captain ? startCase(vehicle.captain?.name) : "-"}
                  </p>
                </InfoWrapperItem>
                <InfoWrapperItem compact>
                  <p>{t("year")}</p>
                  <p>{vehicle.year || "-"}</p>
                </InfoWrapperItem>
                <InfoWrapperItem compact>
                  <p>{t("color")}</p>
                  <p sx={{ margin: 0 }}>{startCase(vehicle.color || "-")}</p>
                </InfoWrapperItem>
              </InfoWrapper>
            </div>
          </TabWrapper>
          <TabWrapper tab={t("documents")} key={"2"}>
            <div
              sx={{
                overflow: "auto",
                flexGrow: "1",
                paddingTop: "spacing-m",
              }}
            >
              <InfoWrapper>
                <VerifiableDocuments
                  verifiableName={vehicle?.plates}
                  verifiableType="bus"
                  cityId={vehicle?.city}
                  noDataComponent={
                    <div
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "350px",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: "spacing-m-l",
                      }}
                    >
                      <img src={EmptyBoxLogo} alt="Empty state logo" />
                      <h5
                        sx={{
                          p: 0,
                          m: 0,
                          mt: "spacing-m-l",
                          variant: "text.h5",
                        }}
                      >
                        {t("no-documents-available", { ns: "common" })}
                      </h5>
                    </div>
                  }
                />
              </InfoWrapper>
            </div>
          </TabWrapper>
        </TabsWrapper>
      </div>
    </div>
  );
};

export default VehicleDetails;
