/** @jsxImportSource theme-ui */
import { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import GA from "react-ga";
import once from "lodash/once";
import omit from "lodash/omit";
import { has } from "lodash";
import {
  startOfToday,
  endOfToday,
  startOfTomorrow,
  endOfTomorrow,
} from "date-fns";
import Drawer from "@swvl/drawer";

import useFilters from "hooks/useFilters";
import {
  useAssignCaptain,
  AssignCaptainRequest,
} from "resources/rides-list/useAssignCaptain";
import {
  useListRides,
  ListRideData,
  ListRideResponse,
} from "resources/rides-list/useListRides";
import { useUnassignCaptain } from "resources/rides-list/useUnassignCaptain";
import { useUnassignRide } from "resources/rides-list/useUnassignRide";
import { UnassignRequestPayload } from "resources/rides-list/useUnassignCaptain";
import { Captain } from "utils/types";
import RidesListing from "./RidesListing";
import UnassignRideCaptain from "./UnassignRideCaptain";
import UpdateRideDrawer from "./UpdateRideDrawer";
import RidesListFilter from "./RidesListFilter";
import RidesListMoreFilter from "./RidesListMoreFilter";

import { RideData, UpdateRideProps } from "./types";
import trackEvents from "constants/trackEvents";
import AppliedFilters from "components/AppliedFilters";
import ToolbarButton from "shared/components/ToolbarButton";
import FilterIcon from "shared/icons/FilterIcon";
import DownloadIcon from "shared/icons/DownloadIcon";

const events = trackEvents.getRideEvents();
const trackScreenRidesList = once((number_of_rides: number) => {
  GA.event({
    ...events.screenRidesList,
    label: JSON.stringify({ number_of_rides }),
  });
});

const SingleUseParams: ["date"] = ["date"];

const Rides = () => {
  const { t } = useTranslation("rides");
  const queryClient = useQueryClient();
  const [showUnassignRideCaptainPopup, setShowUnassignRideCaptainPopup] =
    useState(false);
  const [rideData, setRideData] = useState<RideData>();
  const [rideListingData, setRideListingData] = useState<
    ListRideResponse | undefined
  >({ hits: [], total: 0, page: 0, limit: 0 });

  const [isAssignCaptainDrawerOpen, setAssignCaptainDrawerOpen] =
    useState(false);
  const [isRidesFiltersDrawerOpen, setRidesFiltersDrawerOpen] = useState(false);
  const [assignReassignData, setAssignReassignData] =
    useState<UpdateRideProps>();
  const downloadDataCallbackRef = useRef<() => void>();

  const [filters, dispatchFilters] = useFilters<ListRideData>({
    start_date: startOfToday().toISOString(),
    end_date: endOfToday().toISOString(),
    page: 1,
    limit: 100,
  });

  const sanitizedFilers = { ...omit(filters, SingleUseParams) };

  if (sanitizedFilers.captain_ids) {
    sanitizedFilers.captain_ids = sanitizedFilers.captain_ids.map((value) => {
      const [_id] = value.split("-");
      return _id;
    });
  }

  if (sanitizedFilers.bus_ids) {
    sanitizedFilers.bus_ids = sanitizedFilers.bus_ids.map((value) => {
      const [_id] = value.split("-");
      return _id;
    });
  }

  if (sanitizedFilers.bus_type_ids) {
    sanitizedFilers.bus_type_ids = sanitizedFilers.bus_type_ids.map((value) => {
      const [_id] = value.split("-");
      return _id;
    });
  }

  if (sanitizedFilers.origin_districts) {
    sanitizedFilers.origin_districts = sanitizedFilers.origin_districts.map(
      (value) => {
        const [_id] = value.split("-");
        return _id;
      }
    );
  }

  if (sanitizedFilers.destination_districts) {
    sanitizedFilers.destination_districts =
      sanitizedFilers.destination_districts.map((value) => {
        const [_id] = value.split("-");
        return _id;
      });
  }

  if (sanitizedFilers.ride_category_ids) {
    sanitizedFilers.ride_category_ids = sanitizedFilers.ride_category_ids.map(
      (value) => {
        const [_id] = value.split("-");
        return _id;
      }
    );
  }
  if (sanitizedFilers.ride_id) {
    const [_id] = sanitizedFilers.ride_id.split("-");
    sanitizedFilers.ride_id = _id;
  }

  // disable the query if there is any single use params
  const noSingleUseParams = SingleUseParams.every(
    (param) => filters[param] === undefined
  );
  const { data: rides, isLoading: isRidesListLoading } = useListRides(
    sanitizedFilers,
    noSingleUseParams
  );

  const { mutateAsync: unassignCaptain } = useUnassignCaptain();
  const { mutateAsync: unassignRide } = useUnassignRide();

  const { mutateAsync: assignCaptain, isLoading: isAssignCaptainLoading } =
    useAssignCaptain();

  useEffect(() => {
    if (!isRidesListLoading && rideListingData) {
      trackScreenRidesList(rideListingData.hits.length);

      if (rideListingData.hits.length === 0 && filters.page > 1) {
        dispatchFilters({ type: "UPDATE", filters: { page: 1 } });
      }
    }
  }, [isRidesListLoading, rideListingData]);

  useEffect(() => {
    if (rides) {
      setRideListingData(rides);
    }
  }, [rides]);

  useEffect(() => {
    if (filters.date === "tomorrow") {
      dispatchFilters({
        type: "UPDATE",
        filters: {
          start_date: startOfTomorrow().toISOString(),
          end_date: endOfTomorrow().toISOString(),
          date: undefined,
        },
      });
    }
  }, []);

  const handleAssignReassignCaptain = (params: UpdateRideProps) => {
    setAssignCaptainDrawerOpen(true);
    setAssignReassignData(params);
  };

  const onRegisterDownloadData = (callback: () => void) => {
    downloadDataCallbackRef.current = callback;
  };

  const onUnassignCaptain = (
    { _id: captainId, name: captainName }: Captain,
    rideId: string
  ) => {
    setRideData({ captainId, captainName, rideId });
    setShowUnassignRideCaptainPopup(true);
  };

  const onUnassignRide = (rideId: string) => {
    setRideData({ rideId });
    setShowUnassignRideCaptainPopup(true);
  };

  const handleAssignCaptainSubmit = (params: AssignCaptainRequest) => {
    assignCaptain(params).then(() => {
      setAssignCaptainDrawerOpen(false);
      queryClient.invalidateQueries("rides-list");
    });
  };

  const handleUnassignSubmit = (param: UnassignRequestPayload) => {
    const requestClient = rideData?.captainId ? unassignCaptain : unassignRide;
    requestClient(param).then(() =>
      queryClient.invalidateQueries("rides-list")
    );
  };

  const filtersHasValidProperty = (obj: ListRideData, property: string) => {
    const exists = has(obj, property);
    const valid = obj[property as keyof ListRideData] !== undefined;
    return exists && valid;
  };
  const getAppliedFilters = () => {
    return {
      ...(filtersHasValidProperty(filters, "captain_assigned") && {
        captain_assigned: {
          title: t("captain_assigned"),
          value: filters.captain_assigned ? "Yes" : "No",
        },
      }),
      ...(filtersHasValidProperty(filters, "captain_confirmed") && {
        captain_confirmed: {
          title: t("captain_confirmed"),
          value: filters.captain_confirmed ? "Yes" : "No",
        },
      }),
      ...(filters?.status?.length && {
        status: {
          title: t("status"),
          value: filters.status,
        },
      }),
      ...(filters?.ride_category_ids?.length && {
        ride_category_ids: {
          title: t("category"),
          value: filters.ride_category_ids.map((cat) => cat.split("-")[1]),
        },
      }),
      ...(filters?.captain_ids?.length && {
        captain_ids: {
          title: t("captain"),
          value: filters?.captain_ids.map((capt) => capt.split("-")[1]),
        },
      }),
      ...(filters?.bus_ids?.length && {
        bus_ids: {
          title: t("bus"),
          value: filters?.bus_ids.map((bus) => bus.split("-")[1]),
        },
      }),
      ...(filters?.bus_type_ids?.length && {
        bus_type_ids: {
          title: t("bus-type"),
          value: filters?.bus_type_ids.map((bus) => bus.split("-")[1]),
        },
      }),
      ...(filters?.origin_districts?.length && {
        origin_districts: {
          title: t("origin"),
          value: filters?.origin_districts.map((bus) => bus.split("-")[1]),
        },
      }),
      ...(filters?.destination_districts?.length && {
        destination_districts: {
          title: t("destination"),
          value: filters?.destination_districts.map((bus) => bus.split("-")[1]),
        },
      }),
      ...(filters?.ride_id && {
        ride_id: {
          title: t("id"),
          value: filters?.ride_id.split("-")[0],
        },
      }),
    };
  };

  const clearAppliedFilters = () => {
    dispatchFilters({
      type: "UPDATE",
      filters: {
        page: 1,
        limit: 100,
        captain_assigned: undefined,
        captain_confirmed: undefined,
        status: undefined,
        ride_category_ids: undefined,
        captain_ids: undefined,
        bus_ids: undefined,
        bus_type_ids: undefined,
        origin_districts: undefined,
        destination_districts: undefined,
        ride_id: undefined,
      },
    });
  };

  const removeFilter = ({
    key,
    value,
  }: {
    key: string;
    value: string | string[];
  }) => {
    const _key = key as keyof ListRideData;
    if (
      [
        "captain_ids",
        "bus_ids",
        "bus_type_ids",
        "origin_districts",
        "destination_districts",
        "ride_category_ids",
      ].includes(_key)
    ) {
      let filterValue = filters[_key]
        ? [...(filters[_key] as string[])]
        : undefined;
      if (!filterValue) return;
      filterValue = filterValue?.filter(
        (item) => !item.endsWith(value as string)
      );
      dispatchFilters({
        type: "UPDATE",
        filters: {
          ...filters,
          [key]: [...filterValue],
        },
      });
    } else if (
      _key === "captain_assigned" ||
      _key === "captain_confirmed" ||
      _key === "ride_id"
    ) {
      dispatchFilters({
        type: "UPDATE",
        filters: {
          ...filters,
          [key]: undefined,
        },
      });
    } else {
      dispatchFilters({
        type: "UPDATE",
        filters: {
          ...filters,
          [key]: filters[key as keyof ListRideData]
            ? (filters[key as keyof ListRideData] as string[]).filter(
                (item: string) => item !== value
              )
            : undefined,
        },
      });
    }
  };

  return (
    <div
      sx={{
        px: "spacing-m",
        pt: "spacing-m",
      }}
    >
      <Helmet>
        <title>
          {t("title")} | {t("dashboard-title", { ns: "common" })}
        </title>
      </Helmet>

      <h4 sx={{ variant: "text.h4", mt: 0, mb: "spacing-m" }}>{t("title")}</h4>

      <div
        sx={{
          display: "flex",
          marginBottom: "spacing-m",
          alignItems: "end",
          justifyContent: "space-between",
        }}
      >
        <div
          sx={{
            display: "flex",
            gap: "spacing-s",
            alignItems: "end",
          }}
        >
          <RidesListFilter
            filters={filters}
            dispatchFilters={dispatchFilters}
          />
          <ToolbarButton
            title={t("more-filters")}
            icon={<FilterIcon />}
            onClick={() => {
              setRidesFiltersDrawerOpen(true);
              GA.event(events.actionOpenMoreFilters);
            }}
          />
        </div>
        {rideListingData && rideListingData.hits.length > 0 && (
          <ToolbarButton
            title={t("download-data.download")}
            icon={<DownloadIcon />}
            onClick={() => {
              downloadDataCallbackRef.current?.();
              GA.event(events.actionDownloadRides);
            }}
          />
        )}
      </div>

      {Object.keys(getAppliedFilters()).length ? (
        <AppliedFilters
          title={t("applied-filters")}
          filters={getAppliedFilters()}
          clearAll={clearAppliedFilters}
          removeFilter={removeFilter}
        />
      ) : null}

      <div
        sx={{
          position: "relative",
          zIndex: 0,
        }}
      >
        <RidesListing
          isLoading={isRidesListLoading}
          onAssignCaptain={(params: UpdateRideProps) => {
            GA.event({
              ...events.actionAssignCaptain,
              label: JSON.stringify({ ride_id: params.rideId }),
            });
            handleAssignReassignCaptain(params);
          }}
          onReassignCaptain={(params: UpdateRideProps) => {
            GA.event({
              ...events.actionReassignCaptain,
              label: JSON.stringify({ ride_id: params.rideId }),
            });
            handleAssignReassignCaptain(params);
          }}
          rides={rideListingData}
          filters={filters}
          dispatchFilters={dispatchFilters}
          onUnassignCaptain={onUnassignCaptain}
          onUnassignRide={onUnassignRide}
          onRegisterDownloadData={onRegisterDownloadData}
        />
      </div>
      <UnassignRideCaptain
        showUnassignRideCaptainPopup={showUnassignRideCaptainPopup}
        setShowUnassignRideCaptainPopup={setShowUnassignRideCaptainPopup}
        onUnassignSubmit={handleUnassignSubmit}
        rideData={rideData}
      />

      <Drawer
        isOpen={isAssignCaptainDrawerOpen}
        closeDrawer={() => setAssignCaptainDrawerOpen(false)}
        size={"large"}
      >
        <UpdateRideDrawer
          setUpdateRideDrawerOpen={setAssignCaptainDrawerOpen}
          updateRideData={assignReassignData}
          onUpdateRideSubmit={handleAssignCaptainSubmit}
          isUpdateRideLoading={isAssignCaptainLoading}
        />
      </Drawer>

      <Drawer
        isOpen={isRidesFiltersDrawerOpen}
        closeDrawer={() => {
          setRidesFiltersDrawerOpen(false);
          GA.event(events.actionCloseMoreFilters);
        }}
        size={"medium"}
      >
        <RidesListMoreFilter
          cancel={() => {
            setRidesFiltersDrawerOpen(false);
            GA.event(events.actionCloseMoreFilters);
          }}
          filters={filters}
          dispatchFilters={dispatchFilters}
        />
      </Drawer>
    </div>
  );
};

export default Rides;
