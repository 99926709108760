/** @jsxImportSource theme-ui */
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Column, TableState, ActionType } from "react-table";
import GA from "react-ga";
import Table from "@swvl/table";

import trackEvents from "constants/trackEvents";
import useLang from "hooks/useLang";
import { tablePageSizeOptions as pageSizeOptions } from "utils";

import {
  Vehicle,
  ListVehicleResponse,
  ListVehicleData,
} from "resources/vehicles-list/useListVehicle";
import NoData from "components/NoData";

import { FilterAction } from "hooks/useFilters";

type ListVehicleProps = {
  isLoading?: boolean;
  vehicles: ListVehicleResponse | undefined;
  filters: ListVehicleData;
  dispatchFilters: React.Dispatch<FilterAction<ListVehicleData>>;
  onRowClick: (vehicle: Vehicle | undefined) => void;
};
let lastTableActionType: string;

const VehicleListing = ({
  vehicles,
  isLoading,
  filters,
  dispatchFilters,
  onRowClick,
}: ListVehicleProps) => {
  const [lang] = useLang();
  const { t } = useTranslation("vehicles");
  const events = trackEvents.getVehicleEvents();

  const getRowProps = useCallback((props, original: Vehicle | undefined) => {
    return {
      ...props,
      onClick: (e: MouseEvent) => {
        e.preventDefault();
        onRowClick(original);
      },
      sx: {
        cursor: "pointer",
      },
    };
  }, []);

  const tableStateReducer = (
    state: TableState<Vehicle>,
    { type }: ActionType
  ) => {
    let newState;

    switch (type) {
      case "resetHiddenColumns":
        if (
          lastTableActionType !== "gotoPage" &&
          lastTableActionType !== "setPageSize"
        ) {
          newState = {
            ...state,
            pageIndex: filters.page - 1,
            pageSize: filters.limit,
          };
        }
        break;
    }
    if (!newState) newState = state;

    lastTableActionType = type;
    return newState;
  };

  const columns: Column<Vehicle>[] = [
    {
      Header: `${t("plate-number")}`,
      accessor: "plates",
      id: "plates",
      Cell: ({ value }) => value,
    },
    {
      Header: `${t("bus-type")}`,
      accessor: "type",
      id: "type",
      Cell: ({ value }) => value?.name || "",
    },
    {
      Header: `${t("captain-assigned")}`,
      accessor: "captain",
      id: "captain",
      Cell: ({ value }) => value?.name || "",
    },
  ];

  return (
    <Table
      data={vehicles?.hits || []}
      columns={columns}
      noDataComponent={<NoData title={t("no-data-title", { ns: "common" })} />}
      fullWidth={true}
      sameSizeCells={false}
      locale={t("table-locales", { ns: "common", returnObjects: true })}
      direction={lang.direction}
      enablePagination={true}
      isLoading={isLoading}
      getRowProps={getRowProps}
      stateReducer={tableStateReducer}
      paginationConfig={{
        onPageChange: (page) => {
          dispatchFilters({ type: "UPDATE", filters: { page: page + 1 } });

          GA.event({
            ...events.actionChangePage,
            label: JSON.stringify({ page_number: page + 1 }),
          });
        },
        onPageSizeChange: (limit) => {
          if (limit !== filters.limit) {
            dispatchFilters({ type: "UPDATE", filters: { limit, page: 1 } });

            GA.event({
              ...events.actionChangeRowsPerPage,
              label: JSON.stringify({ page_limit: limit }),
            });
          }
        },
        initialPageSize: filters.limit,
        initialPageIndex: filters.page - 1,
        manualPagination: true,
        totalCount: vehicles?.total || 0,
        pageSizeOptions,
      }}
    />
  );
};

export default VehicleListing;
