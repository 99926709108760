import { useQuery } from "react-query";

import apiInstance from "api";

import { useAuthContext } from "contexts/authContext";
import { BusType, Captain } from "utils/types";

// Types
export type ListVehicleData = {
  captain_id: string;
  page: number;
  limit: number;
};

export type ListVehicleResponse = {
  total: number;
  hits: Vehicle[];
};

export type Vehicle = {
  _id: string;
  plates: string;
  type: BusType;
  captain: Captain;
};

// Resources
const getListVehicles = async (params: ListVehicleData) => {
  return await apiInstance.get<ListVehicleData, ListVehicleResponse>("/bus", {
    params,
  });
};

const useListVehicles = (params: ListVehicleData) => {
  const { dynamicKeys } = useAuthContext();
  return useQuery(["vehicle-list", params, dynamicKeys], () =>
    getListVehicles(params)
  );
};

export { useListVehicles };
